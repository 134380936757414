@use "./src/scss/global.scss" as *;

%rotationConst {
  transition: transform 0.6s cubic-bezier(.69, -0.23, .31, 1.23);
}

.arrow-wrapper {
  height: 30px;
  width: 30px;
  @include flex-center;
}

.interactive-arrow {
  @extend %rotationConst;

  &.default {
    transform: rotate(180deg);
  }

  &.hover {

  }

  &.active {
    transform: rotate(0deg);
  }
}