@use "./src/scss/global.scss" as *;

.partnershipsSlider {
  .slick-track {
    display: flex;
    align-items: center;
  }

  .partnership {
    margin-right: 56px;
    margin-bottom: $size-sm;
  }
}
