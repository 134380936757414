@use "./src/scss/global.scss" as *;

#products-skey-box-offer {
  padding-top: 130px;

  .header {
    @include h1;
  }

  .title-offer {
    font-weight: bold !important;
  }

  .offer-mobile {
    padding-top: 78px;
  }

  .flex-center {
    @include flex-column(center, flex-start);
  }

  @include mq($mobile) {
    padding-top: 80px;
    .s1 {
      font-size: 20px !important;
    }
    .s2 {
      font-size: 15px !important;
    }
  }
}
