@use "./src/scss/global.scss" as *;

.skey-tix-faq {
  padding-top: 80px;

  .nft-faq-link {
    font-size: 15px !important;
    display: inline-block;
    padding: 0;
  }

  .nft-faq-list {
    li {
      font-size: 15px !important;
    }
  }

  @include mq($mobile) {
    &-title {
      text-align: center;
    }
  }
}
