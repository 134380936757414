@use "./src/scss/global.scss" as *;

#use-cases {
  padding-top: 120px;
  padding-bottom: 15px;

  .center-column {
    margin: auto;
  }

  .header,
  .subheader {
    text-align: center;
    margin: auto;
  }

  .header {
    @include h1;
  }

  .subheader {
    width: 100%;
    max-width: 520px;
  }

  .space {
    height: 60px;
  }

  .card-size {
    width: 100%;
    max-width: 720px;
    margin: auto;
  }

  .links-holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 23px;
    column-gap: 0px;
  }

  .link-holder {
    max-width: 200px;
    width: 100%;

    .skey-link {
      padding: 0px;
    }
  }

  @include mq($mobile) {
    // .space {
    //   height: 73px;
    // }
  }
}
