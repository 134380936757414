@use "./src/scss/global.scss" as *;

.shadow-box {
  background-color: $white-0;

  padding: $size-5;
  border-radius: 20px;
  height: max-content;
  width: 100%;
  box-shadow: $c2;

  @include mq-max($md) {
    padding: $size-5 $size-2;
    padding-top: $size-2;
  }
}
