@use "./src/scss/global.scss" as *;
#news-hero {
  .news-bg-blocks {
    top: -60px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .link-news {
    border: transparent;
  }

  .header {
    // @include text-scaffold(56px, 300, #120e2f, 1.4, -0.01em);
    width: 100%;
    max-width: 772px;
    text-align: center;
  }

  .fluid-xl {
    @include flex-column(center, flex-start);
  }

  .col-center {
    @include flex-column(center, flex-start);
    padding-top: 448px;
  }

  .subheader {
    width: 100%;
    max-width: 530px;
    text-align: center;
    letter-spacing: 0.01em;
  }

  .links {
    display: flex;
    flex-direction: row;
    order: 1;
  }

  .skey-link + .skey-link {
    margin-left: $size-sm;
  }

  @include mq(1170px) {
    .skey-link {
      margin-left: unset !important;
      width: 100% !important;
    }

    .skey-link + .skey-link {
      margin-top: $size-sm;
    }

    .links {
      flex-direction: column;
      width: 100%;
    }

    .col-center {
      padding-top: 315px;
    }

    .news-bg-blocks {
      top: -50px;
    }
  }
}
