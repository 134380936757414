@use "../../../../../scss/global.scss" as *;

.solutions {
  padding-top: 200px;

  @include mq-max($lg) {
    padding-top: 80px;
  }

  .video {
    width: 100%;
    max-width: 700px;
    aspect-ratio: 16/9;
  }

  .video-placeholder {
    .play-button-bg {
      opacity: 0.7;
      transition: opacity 0.12s ease-in-out;
    }

    &:hover {
      .play-button-bg {
        opacity: 1;
      }
    }
  }
}
