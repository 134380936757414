@use "./src/scss/global.scss" as *;

.skey-tooltip {
  position: absolute;
  top: 50%;
  width: max-content;

  &.right {
    right: 50%;
  }

  &.left {
    left: 50%;
  }
}