@use "./src/scss/global.scss" as *;

.accordion-tab {
  width: 100%;
  height: max-content;
  @include flex-column(flex-start, space-between);

  .accordion-tab-top {
    width: 100%;
    @include flex-row(space-between);
  }
}