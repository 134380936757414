@use "../../../scss/global.scss" as *;

#passport {
  h1 {
    @include text-scaffold(60px, 400, $dark, 105%, -0.01em);

    @include mq-max($md) {
      @include text-scaffold(38px, 400, $dark, 140%, -0.02em);
    }
  }

  h2 {
    @include text-scaffold(48px, 400, $dark, 140%, -0.01em);

    @include mq-max($md) {
      @include text-scaffold(38px, 400, $dark, 140%, -0.02em);
    }
  }

  h3 {
    @include text-scaffold(38px, 400, $dark, 1.4, 0em);
    @include mq-max($md) {
      @include text-scaffold(20px, 400, $dark, 120%, -0.02em);
    }
  }
  h4 {
    @include text-scaffold(24px, 400, $dark, 1.4, 0em);
    @include mq-max($md) {
      @include text-scaffold(20px, 400, $dark, 120%, -0.02em);
    }
  }

  .s2 {
    // font-size: 1px !important;

    @include mq-max($md) {
      font-size: 14px !important;
    }
  }

  background-color: $dirty-white;
  .hero-img {
  }

  .list-item {
    &:first-child {
      .description {
        font-family: "Mazzard" !important;
        background: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 28px !important;
      }
    }
  }
}
