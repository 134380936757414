@use "../../../../scss/global.scss" as *;

.staking-button {
  @keyframes anime {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  height: 40px;
  width: max-content;
  padding: 0px 16px;
  border-radius: 8px;

  background: linear-gradient(-45deg, #3495ff, #4f3acf, #bb5dee, #4f3acf, #3495ff, #bb5dee);
  animation: anime 10s cubic-bezier(0.56, 0.02, 0.52, 1) infinite;
  background-size: 1000%;
  // background: linear-gradient(-90deg, #3495ff, #4f3acf, #bb5dee);
  // background: linear-gradient(-90deg, #8b51ed, #5c44ec, #bb5dee, #3495ff, #bb5dee);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: -5px 5px 10px 0px rgba(92, 68, 236, 0.2), 5px -5px 10px 0px rgba(255, 255, 255, 0.2),
      5px 5px 13px 0px rgba(92, 68, 236, 0.2);
  }
}
