@use "../../../scss/global.scss" as *;

.content-with-video {
  padding: 20px;
  border-radius: 20px;
  background-color: $white-0;
  box-shadow: $c2;
  border: 1px solid $dirty-white-3;

  @include mq-max($md) {
    box-shadow: none;
    padding-bottom: 50px;
  }
}
