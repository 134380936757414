@use "./src/scss/global.scss" as *;

.input {
  $color: $dark;
  border: none;
  outline: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: rgba($color, 1);
  border-bottom-color: rgba($dark, 0.1);
  padding-top: 8px;
  padding-bottom: 8px;

  transition: border-bottom-color 0.15s ease-in-out;

  &::placeholder {
    color: rgba($dark, 0.3);
  }

  &[value=""] {
  }
  &:focus {
  }
  &:not([value=""]) {
    color: rgba($color, 1);
  }
  &.error {
    border-bottom-color: rgba($danger, 1);
  }
  &:disabled {
    border-bottom: none;
    background: unset;
  }
}
