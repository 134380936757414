@use "../../../../scss/global.scss" as *;

.agreements-section {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 33px;
  align-items: flex-start;

  &-first-col {
    max-width: 160px;
    width: 100%;
  }

  p {
    color: rgba($dark, 0.4);
    margin-top: 11px;
  }

  a {
    display: initial;
  }

  .label-required {
    color: $danger;
  }

  @include mq(576px) {
    flex-flow: column nowrap;

    &-second-col {
      width: 100%;
    }
  }
}
