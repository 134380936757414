@use "../../../scss/global.scss" as *;

#rwa {
  h1 {
    @include text-scaffold(60px, 400, $dark, 105%, -0.01em);

    @include mq-max($md) {
      @include text-scaffold(38px, 400, $dark, 140%, -0.02em);
      font-size: 38px !important;
    }
  }

  h2 {
    @include text-scaffold(48px, 400, $dark, 140%, -0.01em);

    @include mq-max($md) {
      @include text-scaffold(38px, 400, $dark, 140%, -0.02em);
      font-size: 38px !important;
    }
  }

  h3 {
    @include text-scaffold(38px, 400, $dark, 1.4, 0em);
    @include mq-max($md) {
      @include text-scaffold(20px, 400, $dark, 120%, -0.02em);
      font-size: 28px !important;
    }
  }
  h4 {
    @include text-scaffold(24px, 400, $dark, 1.4, 0em);
    @include mq-max($md) {
      @include text-scaffold(20px, 400, $dark, 120%, -0.02em);
    }
  }

  background-color: $dirty-white;
}
