@use "src/scss/global.scss" as *;

.hero {
  .title {
    max-width: 530px;

    @include mq-max($md) {
      max-width: unset;
      width: 100%;
      padding-top: 145px;
      text-align: center;
    }
  }

  .bg {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: -1;

    @include mq-max($md) {
      left: 50%;
      top: -55px;
      transform: translateX(-50%);
    }
  }
}
