@use "./src/scss/global.scss" as *;

.cooperation-card {
  padding: $size-xl;
  box-shadow: $c1-default;
  background: $white;
  border-radius: 15px;
  width: 100%;
  @include flex-column(flex-start, space-between);
  @extend %transition-shadow;

  .link p {
    color: $primary;
  }

  &:hover {
    box-shadow: $c1-hover;
  }

  .h-45 {
    height: 52px;
    width: auto;
    max-width: 200px;
  }

  .card-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .descriptions {
    @include text-scaffold(15px, 400, #120e2f, 24px, -0.01em);
  }

  .title-card {
    @include text-scaffold(38px, 300, #120e2f, 140%, -0.02em);
  }

  .link {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .links-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: $size-xl;
  }

  .link-holder {
    max-width: max-content;
    width: 166px;

    .skey-link {
      padding-top: 0px;
      padding-bottom: 20px;
    }
  }

  .img-max-width {
    img {
      width: 39px;
      height: max-content;
      max-height: 32px;
    }
  }

  @include mq($mobile) {
    min-height: unset;
    padding: 20px;
    padding-top: 25px;

    .h-45 {
      height: 40px;
    }
  }
}
