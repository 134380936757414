@use "../../../../scss/global.scss" as *;

.checkbox-section {
  label {
    opacity: 0.4;
  }

  .checkboxes-container {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    column-gap: 33px;
  }
}
