@use "./src/scss/global.scss" as *;

.anchor {
  position: relative;
  width: 60px;
  height: 60px;
  left: 50%;
  transform: translateX(-50%);
  min-height: 350px;

  .story-card {
    box-shadow: $c1-default;
  }
  
  
  @include mq($desktop) {
    width: 100%;
    height: unset;
    left: unset;
    transform: unset;
    min-height: unset;
    
    .story-card {
      position: initial !important;
      transform: unset !important;
      right: unset !important; 
      left: unset !important;
      text-align: left !important;
      margin-bottom: 16px !important;
      border-radius: 20px !important;
      max-width: 530px;
      min-width: unset;
      width: 100%;
    }
  }
}