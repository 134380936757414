@use "./src/scss/global.scss" as *;

.skey-tix-administration-panel {
  padding-top: 82px;

  .header-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;

    .title {
      @include h1;
    }

    .description {
      max-width: 527px;
    }
  }
}
