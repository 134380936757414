@use "./src/scss/global.scss" as *;

#news-articles {
  padding-top: 112px;

  .imageCard-col {
    margin-bottom: 80px;
  }

  .card-col {
    padding: 20px;
  }

  .row {
    --bs-gutter-x: 20px;
    row-gap: 20px;
  }

  @include mq(768px) {
    padding-top: 100px;

    .card-col {
      margin-bottom: $size-sm;
    }

    .imageCard-col {
      margin-bottom: $size-sm;
    }

    .imageCard-col + .imageCard-col {
      margin-bottom: $size-xl;
    }
  }
}
