@use "./src/scss/global.scss" as *;

.accordion-tab {
  width: 100%;
  height: max-content;
  position: relative;

  &-divider {
    opacity: 0.3;
    height: 1px;
    background-color: $dark;
    margin-bottom: 20px;
    width: 100%;

    &-hover {
      opacity: 0.8;
      background-color: $primary;
    }
  }
}
