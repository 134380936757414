@use "./src/scss/global.scss" as *;

.router-link {
  @include text-scaffold(15px, 400, $dark, 19px, 0.03em);
  transition: all 0.1s ease-out;
  padding: 8px;

  &:hover {
    // font-weight: 700;
    color: #868686;
  }

  &.active {
    // font-weight: 700;
    color: $primary;
  }
}
