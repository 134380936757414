@use "./src/scss/global.scss" as *;

.story-line {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  top: 30px;
  background-image: url("../../../../../public/images/line-dx.svg");
  background-repeat: repeat-y;
}