@use "./src/scss/global.scss" as *;

#news-story {
  padding: 112px 0 200px;

  .header {
    @include h1;
    text-align: center;
  }

  @include mq($desktop) {
    padding: 80px 0 174px;
    .header {
      width: 100%;
      max-width: max-content;
      text-align: left;
      font-weight: $regular;
    }
  }
}
