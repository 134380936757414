@use "../../../../../scss/global.scss" as *;

.hero {
  padding-top: 120px;

  @include mq-max($md) {
    padding-top: 0px;

    .video {
      max-width: 100%;
      height: auto;
      vertical-align: baseline;
    }
  }

  .video {
    max-width: 100%;
    height: auto;
    vertical-align: baseline;
  }
}
