@use "../../../../../scss/global.scss" as *;

.more-info {
  padding-top: 260px;
  padding-bottom: 180px;

  // .generic-link {
  //   height: 62px;
  //   padding: 0px 16px;
  //   box-shadow: $c1-default;
  p {
    font-size: 16px;
  }
  //   border-radius: 8px;
  //   border: 1px solid $dirty-white-2;

  //   @extend %transition-shadow;

  //   &:hover {
  //     box-shadow: $c1-hover;
  //   }
  // }

  @include mq-max($lg) {
    padding-top: 100px;
    padding-bottom: 150px;

    // .generic-link {
    //   width: 100%;
    // }
  }
}
