@use "./src/scss/global.scss" as *;

#footer {
  background-color: $dark;
  padding-top: 111px;
  padding-bottom: 136px;

  overflow-y: hidden;

  .linked-in {
    color: $primary-dark-mode;

    &:hover {
      color: $primary-dark-mode-hover;
    }
  }

  .social-media {
    justify-content: space-between;
    width: 100%;
    max-width: 375px;
  }

  .tooltip-styling {
    padding: 23px 32px;
    border-radius: 10px;
    background: linear-gradient(159.1deg, rgba(255, 255, 255, 0.1) -0.09%, rgba(255, 255, 255, 0) 171.38%), #120E2F;

  }

  .social-media-link {
    @include text-scaffold(15px, 400, #7F70DB, 1.7, 0.01em);
    line-height: 48px;
  }

  .tooltip-title, .title {
    text-align: start;
    color: #D0CFD5;
  }

  .children-as-white {
    p {
      color: $dirty-white;
    }
  }

  @include mq(991px) {
    padding-top: 69px;
    padding-bottom: 48px;
  }
}

#pdfs {
  background-color: $black;
  padding: 21px 17px;

  .title {
    text-align: start;
    color: #D0CFD5;
  }

  .terms-and-conditions {
    max-width: 300px;
    width: 100%;
    @include flex-row(space-between);
  }
}
