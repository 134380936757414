@use "./src/scss/global.scss" as *;

#products-blockchain {

  @include mq($mobile) {
    h1, h3 {
      font-weight: $regular;
    }
  }
}
