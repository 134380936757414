@use "../../../../../scss/global.scss" as *;

.rwa-hero {
  padding-top: 30px;

  .hero-img {
    max-width: 100%;
  }

  @include mq-max($md) {
    padding-top: 20px;
  }
}
