@use "./src/scss/global.scss" as *;

.scroll-to-box {
  @include flex-column(flex-start, flex-start);

  .title {
    color: $white;
  }

  .footer-link {
    @include text-scaffold(12px, 400, $primary-dark-mode, 190%, 0.02em);

    &:hover {
      color: $primary-dark-mode-hover;
    }

    &:disabled {
      opacity: 0.26;
    }
  }
}