@use "./src/scss/global.scss" as *;

#solutions-skey-cards {
  padding-top: 120px;

  .row {
    --bs-gutter-x: 43px;
  }

  .card-col {
    margin-bottom: 32px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include mq(768px) {
    padding-top: 80px;

    .card-col {
      margin-bottom: 16px;
    }
  }
}
