@use "./src/scss/global.scss" as *;

.interactive-logo {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  will-change: opacity;

  &.default {
    box-shadow: 0px 106.997px 142.663px rgba(0, 0, 0, 0.04), 0px 71.3314px 106.997px rgba(0, 0, 0, 0.04), 0px 17.8328px 35.6657px rgba(0, 0, 0, 0.04), 0px 0px 4.45821px rgba(0, 0, 0, 0.04);
  }
  &.hover {
    box-shadow: 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.04), 0px 11.7021px 23.4043px rgba(0, 0, 0, 0.04), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);
  }
  &.active {

  }
}
.interactive-logo-wrapper {
  position: relative;
  min-height: 80px;
  min-width: 80px;
  @include flex-center;

}
