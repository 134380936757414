@use "./src/scss/global.scss" as *;

.exchange-card {
  box-shadow: $c1-default;
  border-radius: 10px;
  padding: 20px 10px;
  background-color: $white;
  transition: box-shadow 0.3s ease-out;
  height: 100%;
  width: 100%;

  &:hover {
    box-shadow: $c1-hover;
  }
}