@use "./src/scss/global.scss" as *;

.skey-tix-operation {
  padding-top: 120px;

  &-container {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 75px;

    .operation-header {
      max-width: 530px;
    }

    .operation-main {
      display: flex;
      flex-flow: row nowrap;
      gap: 40px;

      .operation-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;

        .operation-card {
          max-width: 244px;
          width: 100%;
        }
      }
    }

    .operation-links {
      padding-top: 60px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 20px;

      .stores-links {
        display: flex;
        gap: 20px;
      }

      .store-app-link {
        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
          box-shadow: $button-hover-shadow;
        }
      }
    }
  }

  @include mq(768px) {
    .operation-header {
      max-width: 100%;
      text-align: center;
    }

    .operation-main {
      flex-direction: column;
    }

    .operation-cards {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    .operation-links {
      flex-direction: column;
    }

    .operation-main-image {
      margin: 0 auto;

      .image-tix-app {
        width: 286px;
        height: auto;
      }
    }
  }
}
