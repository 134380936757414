@use "./src/scss/global.scss" as *;

#overview-documentation {
  padding-top: 190px;
  padding-bottom: 180px;

  .as-center-col {
    @include flex-column(center, flex-start);
  }

  * {
    text-align: center;
  }

  .header {
    width: 100%;
    max-width: 460px;
    order: 1;
  }

  .space-1 {
    order: 2;
  }

  .buttons {
    @include flex-row(center);
    max-width: 550px;
    width: 100%;
    order: 3;

    a ~ a {
      margin-left: $size-sm;
    }
  }

  .space-2 {
    order: 4;
  }

  .header-overview {
    max-width: 520px;
    @include h1;
  }

  .subheader {
    max-width: 550px;
    width: 100%;
    order: 5;
  }

  .space-3 {
    order: 6;
  }

  .doc-row {
    order: 7;
    width: 100%;
  }

  .doc-card {
    .title {
      min-height: 85px;
    }

    .header {
      margin-bottom: 24px;
    }
  }

  @include mq($mobile) {
    padding-top: 80px;
    padding-bottom: 131px;

    .subheader {
      order: 3;
    }

    .buttons {
      order: 5;
      @include flex-column(center, flex-start);

      a {
        width: 100% !important;
      }

      a ~ a {
        margin-top: $size-sm;
        margin-left: 0px;
      }
    }

    .doc-card {
      .title {
        min-height: unset;
      }
    }
  }
}
