@use "./src/scss/global.scss" as *;

.interactive-text {
  @include flex-row(flex-start);
  position: relative;

  &.active > * {
    color: $dark !important;
  }

  &.light > * {
    font-weight: 300;
  }

  &.regular > * {
    font-weight: 400;
  }

  &.bold > * {
    font-weight: 700;
  }

  .badge-position {
    position: absolute;
    right: -20px;
    top: -2px;
  }
}
