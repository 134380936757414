@use "./src/scss/global.scss" as *;

.contact-section {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 40px 17px;
  text-align: center;
  background-color: $dirty-white-3;

  > * {
    margin: auto;
    width: 100%;
  }

  .header {
    font-size: 28px !important;
  }

  .subheader {
    max-width: 530px;
    width: 100%;
    font-size: 15px !important;
  }

  button {
    max-width: max-content;
  }

  @include mq($mobile) {
    .button-contact {
      max-width: 100%;
    }
  }
}
