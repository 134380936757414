@use "../../../../../scss/global.scss" as *;

.new-requirements {
  padding-top: 150px;

  .requirement {
    width: 240px;
  }

  @include mq-max($md) {
    padding-top: 120px;
    .requirement {
      width: 100%;
    }
  }
}
