@use "./src/scss/global.scss" as *;

#products-blockchain-features {
  padding-top: 120px;

  .header-main {
    text-align: center;
    @include h1;
  }

  .header {
    width: max-content;
    margin: auto;
  }

  .gaps {
    row-gap: 62px;
  }

  @include mq($mobile) {
    .gaps {
      row-gap: 48px;
    }
  }
}
