@use "./src/scss/global.scss" as *;

.overview-dex {
  padding-top: 120px;

  .title {
    font-size: 20px !important;
  }

  .wrapper {
    // opacity: 0.35;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;

    .exchange-col {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .link {
      padding: 24px;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      opacity: 0.4;

      transition: opacity 0.3s ease-in-out;

      img {
        transition: filter 0.3s ease-in-out;
        filter: grayscale(1);
      }

      &:hover {
        opacity: 1;

        img {
          filter: grayscale(0);
        }
      }
    }
  }
}
