@use "./src/scss/global.scss" as *;

#products-skey-tix {
  @include mq($mobile) {

    h1, h3 {
      font-weight: $regular;
    }

    .s2 {
      font-size: 15px !important;
    }
  }
}
