@use "../../../../../../scss/global.scss" as *;

.section-stack {
  width: 100%;
  max-width: 530px;

  button,
  a {
    p {
      font-size: 18px !important;
    }
  }
}
