@use "./src/scss/global.scss" as *;

.product-pricing {
  padding: 40px;
  background: #ffffff;
  box-shadow: $c1-default;
  border-radius: 20px;

  .pricing-header {
    opacity: 0.4;
    display: flex;
    width: 100%;

    &-col {
      flex: 1;
    }

    &-serviceName {
      max-width: 200px;
      min-width: 200px;
    }
  }

  .service-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    opacity: 0.4;
  }

  .subHeader-starter {
    font-weight: bold !important;
  }

  .offer {
    grid-auto-flow: column;
    display: grid;
  }

  .price {
    color: $primary;
  }

  .modules-title {
    background-color: #f7f7f7;
    padding: 7px 0 7px 10px;
    border-radius: 5px;
  }

  .button-offer {
    padding: 16px 85px !important;
  }

  .wide {
    max-width: 260px;
    width: 100%;
  }
}

@include mq($mobile) {
  .product-pricing {
    display: none;
  }
}
