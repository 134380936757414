// @use "./src/scss/utils.scss" as *;
@use "./src/scss/global.scss" as *;

.story-card {
  min-width: 530px;
  width: 100%;
  padding: $size-xl;
  box-shadow: $c1-default;
  position: relative;
  background-color: $white;
  top: -15px;
  z-index: -1;
  @extend %transition-shadow;

  &:hover {
    box-shadow: $c1-hover;
  }

  &.left {
    border-radius: 0px 20px 20px 20px;
    text-align: left;
    left: 80%;
  }

  &.right {
    border-radius: 20px 0px 20px 20px;
    text-align: right;
    right: -13px;
    transform: translateX(-100%);

    .d-unset {
      display: unset;
      img {
        margin-top: -2px;
      }
    }
  }

  @include mq(991px) {
    p {
      font-size: 15px;
    }
    p.s1 {
      font-size: 20px !important;
    }

    p.s2 {
      font-size: 15px !important;
    }

    padding: 40px 20px;
  }
}
