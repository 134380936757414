@use "./src/scss/global.scss" as *;

#overview {
  overflow-x: unset;
  // overflow-y: visible;
  padding-left: 0px;
  padding-right: 0px;
  & > * {
    padding-left: $size-sm;
    padding-right: $size-sm;
  }
}
