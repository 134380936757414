@use "./src/scss/global.scss" as *;

.skey-tix-cooperation {
  padding-top: 120px;

  .cooperation-box {
    display: flex;
    flex-flow: row nowrap;
  }

  .cooperation-content {
    min-height: 316px;
  }

  .cooperation-content,
  .cooperation-image-box {
    flex: 1;
  }

  .cooperation-image-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-cooperation {
    padding-top: 20px;
    max-width: 340px;
  }

  @include mq($mobile) {
    padding-top: 100px;

    .header,
    .subheader {
      text-align: center;
    }

    .cooperation-box {
      flex-flow: column nowrap;
    }

    .cooperation-content {
      min-height: unset;
    }

    .cooperation-image-box {
      padding-top: 40px;

      img {
        margin: 0 auto;
      }
    }
  }
}
