@use "./src/scss/global.scss" as *;

#products-hero {
  .products-bg-blocks {
    top: -145px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .content-center {
    padding-top: 340px;
  }

  .header-products {
    width: 100%;
    max-width: 560px;
    text-align: center;
    margin: auto;
  }

  .subheader {
    width: 100%;
    max-width: 665px;
    text-align: center;
    letter-spacing: 0.01em;
    margin: auto;
    font-size: 20px;
    font-weight: 400;
  }

  @include mq($mobile) {
    .content-center {
      padding-top: 280px;
    }

    .header {
      font-size: 38px !important;
    }
  }
}
