@use "./src/scss/global.scss" as *;

#solutions {
  overflow: hidden;
  #overview-documentation {
    .buttons,
    .spacing.space-2 {
      display: none;
    }
  }
}
