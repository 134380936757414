@use "./src/scss/global.scss" as *;

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(7px);
    opacity: 1;
  }
  50% {
    transform: translateX(-7px);
  }
  75% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes disable-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.min-label {
  opacity: 0;

  &.shake {
    animation: horizontal-shaking 0.2s ease-in-out forwards;
    animation-iteration-count: 1;
  }

  &.stop-shake {
    animation: disable-opacity 0.2s ease-in-out forwards;
    animation-iteration-count: 1;
  }
}

#overview-buy-box {
  .center-box {
    width: 100%;
    height: max-content;
    border: 1px solid rgba($black-0, 0.1);
    border-radius: 12px;
    padding: $size-5;

    @include mq-max($md) {
      padding: $size-3 $size-2;
      padding-bottom: $size-2;
    }
  }

  .payment-methods-box {
    background-color: $dirty-white-1;
    border-radius: $roundness-100;
    border: 1px solid rgba($black-0, 0.1);
    width: 100%;
    max-width: 210px;
    padding: 8px 20px;
  }

  .buy-btn {
    width: 100%;
    max-width: 360px;
  }

  .dropdown-list {
    width: max-content;
    background-color: $white;
    box-shadow: $c1-hover;
    border-radius: 28px;
    padding: 16px;
  }

  .input-line {
    @include mq-max($sm) {
      width: 100%;
    }
  }

  .buy-input {
    // all: unset;
    text-align: right;
    min-width: 150px;
    max-width: 200px;

    @include mq-max($sm) {
      width: 100%;
      min-width: unset;
      max-width: unset;
    }
  }

  .chips-wrapper {
    min-width: 112px;

    @include mq-max(390px) {
      min-width: unset;
    }
  }
}
