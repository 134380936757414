@use "./src/scss/global.scss" as *;

#exchange-stocks {
  padding: 48px 0 70px;

  .col-wrapper {
    @include flex-row(flex-start);
  }

  .exchanges {
    @include flex-row(flex-start);
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq($desktop) {
      justify-content: flex-start;
    }
  }

  .exchange-link.skey-link {
    display: block;
    width: 170px !important;
    min-height: 240px;
    background-color: $white;

    @include mq(991px) {
      width: 100% !important;
    }
  }

  .skey-links {
    max-width: 340px;

    > span,
    > a,
    > button {
      padding: 5px 0px !important;
      width: 100%;
      justify-content: flex-start;
    }
  }

  @include mq($mobile) {
    padding: 80px 0 80px;

    .skey-links {
      margin-left: 0px;
    }

    .exchange-link {
      margin-bottom: 10px;
    }
  }

  @include mq(400px) {
    .exchange-link {
      width: 140px !important;
    }
  }
}
