@use "./src/scss/global.scss" as *;

#overview-partnerships {
  padding-top: 280px;

  .title {
    color: #120e2f;
    margin: 0 auto;
    font-weight: $regular;
    @include mq($mobile) {
      font-size: 20px !important;
    }
  }

  .partnerships {
    opacity: 0.35;
    width: 100%;
  }

  .wrapper {
    width: 100%;
    max-width: 1095px;
  }

  .top {
    margin-bottom: $size-md;
  }

  @include mq($desktop) {
    padding-top: 80px;
  }
}
