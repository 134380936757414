@use "./src/scss/global.scss" as *;

.input-wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    color: $dark;
    opacity: 0.4;

    &.focus {
      opacity: 1;
    }
    &.typed {
      opacity: 0.4;
    }
    &.error {
      opacity: 1;
    }
    &.required{
      color: $dark;
    }
    &.required::after {
      content: "*";
      color: $dark;
      opacity: 1;
    }

  }
  .down-arrow {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 16px;
  }

  .skey-input {
    width: 100%;
    position: relative;
    height: max-content;
    border: 0px;
    border-bottom: 1px solid #120e2f40;
    background-color: transparent;
    padding: 4px 0px;
    @include text-scaffold(15px, 400, $dark, 1.7, 0.01em);


    &:focus {
      outline: none;
      border-bottom: 1px solid $primary;
      caret-color: $primary;
      background-color: transparent;
    }

    &.typed {
      border-bottom: 1px solid $dark;
    }

    &::placeholder {
      @include text-scaffold(15px, 400, $dark, 1.7, 0.01em);
      opacity: 0.4;
    }

    &.error {
      color: $danger;
      border-bottom: 1px solid $danger;
      &::placeholder {
        color: $danger;
      }
    }
  }

  .error-text {
    @include text-scaffold(12px, 400, $danger, 1.7, 0.01em);
    min-height: 20px;
    width: 100%;
  }
}
