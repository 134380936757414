@use "./src/scss/global.scss" as *;

.skey-backdrop {
  height: 100vh;
  width: 100vw;
  background-color: rgba(18, 14, 47, 0.7);
  position: fixed;
  z-index: 6;
  left: 0px;
  top: 0px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .backdrop-filler {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  @include mq(576px) {
    width: 100%;
  }
}
