@use "./src/scss/global.scss" as *;

#development-hero {
  padding-top: 132px;

  .header {
    font-size: 38px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin: auto;
    padding-top: 72px;
  }

  .subheader {
    width: 100%;
    max-width: 340px;
    text-align: center;
    margin: auto;
  }

  .development-bg-blocks {
    position: absolute;
    top: -230px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include mq($mobile) {
    padding-top: 112px;

    .header {
      padding-top: 87px;
    }

    .development-bg-blocks {
      top: -162px;
    }
  }
}
