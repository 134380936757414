@use "./src/scss/global.scss" as *;

.copy {
  position: relative;

  &.copied:after {
    content: "Copied!";
    position: absolute;
    right: -30px;
    top: -30px;
    color: $success;
    border-radius: 8px;
    padding: 10px 20px;
    width: max-content;
    background-color: white;
    box-shadow: $c1-default;
  }
}