@use "./src/scss/global.scss" as *;

.interactive-divider {
  height: 1px !important;
  width: 100% !important;
  background-color: $black;
  opacity: 0.2;

  &.hover {
    background-color: $primary;
    opacity: 1;
  }
  &.active {
    background-color: $dark;
    opacity: 1;
  }
}