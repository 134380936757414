@use "./src/scss/global.scss" as *;

#products-skey-box-modules {
  padding-top: 80px;

  .cards-modules {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 8px;
    max-width: 340px;
    height: 428px;
    background: #ffffff;
    box-shadow: $c1-default;
    border-radius: 20px;
  }

  .modules-header {
    font-size: 20px !important;
  }

  .modules-title {
    font-size: 15px !important;
  }

  @include mq($mobile) {
    .cards-modules {
      max-height: 395px;
    }
    .modules {
      text-align: center;
    }
    .title {
      font-size: 15px !important;
      text-align: center;
    }
  }
}
