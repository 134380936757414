@use "./src/scss/global.scss" as *;

#tokenomy {
  padding-top: 120px;

  > * {
    margin: auto;
    text-align: center;
  }

  .header {
    @include h1;
  }

  .tokenomy-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  @include mq($mobile) {
    .tokenomy-img {
      max-width: 100%;
      position: static;
      left: unset;
      transform: unset;
      margin: auto;
    }
  }
}
