.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-list {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;

  transform-origin: top right;

  animation-duration: 0.1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  @keyframes open {
    0% {
      opacity: 0;
      transform: translate(5px, -5px) scale(0.7);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 34px) scale(1);
    }
  }

  @keyframes close {
    0% {
      visibility: visible;
      opacity: 1;
      transform: translate(0px, 34px) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(5px, -5px) scale(0.7);
      visibility: hidden;
    }
  }

  &.init {
    visibility: hidden;
    opacity: 0;
  }

  &.opened {
    animation-name: open;
    visibility: visible;
  }

  &.closed {
    animation-name: close;
  }
}
