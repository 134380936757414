@use "../../../scss/global.scss" as *;

.list-item {
  max-width: 285px;

  .title {
    font-size: 15px !important;
  }

  .description {
    letter-spacing: -0.015em;
    white-space: pre-wrap;
    font-size: 20px !important;
    line-height: 1.2;
  }
}
