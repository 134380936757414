@use "../../../../../scss/global.scss" as *;

.gains {
  padding-top: 40px;
  .gain {
    width: 220px;
  }

  @include mq-max($md) {
    padding-top: 80px;
    .gain {
      width: 100%;
    }
  }
}
