@use "./src/scss/global.scss" as *;

.story-tab {
  overflow-y: hidden;
  width: 100%;
  height: max-content;

  .content-holder {
    overflow-x: hidden;
    height: max-content;
  }
}