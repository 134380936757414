@use "../scss/global.scss" as *;

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
}

.capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}

.capitalize-each {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.none-events {
  pointer-events: none;
}

.mazzard {
  font-family: Mazzard !important;
}

.white-0 {
  color: $white-0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.col {
  flex-direction: column !important;
}
.row {
  flex-direction: row !important;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.wrap {
  flex-wrap: wrap;
}
.grid {
  flex-wrap: wrap !important;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.light {
  font-weight: $light !important;
}
.regular {
  font-weight: $regular !important;
}
.bold {
  font-weight: $bold !important;
}

.primary {
  color: $primary;
}

.op-0 {
  opacity: 0;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-04 {
  opacity: 0.4;
}
.op-05 {
  opacity: 0.5;
}
.op-06 {
  opacity: 0.6;
}
.op-07 {
  opacity: 0.7;
}
.op-08 {
  opacity: 0.8;
}
.op-09 {
  opacity: 0.9;
}
.op-10 {
  opacity: 1;
}
