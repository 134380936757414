@use "../../../../scss/global.scss" as *;

.checkbox {
  height: 40px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
