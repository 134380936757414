@use "./src/scss/global.scss" as *;

.skey-tix-benefits {
  padding-top: 120px;

  .benefits-content {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .benefits-list {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;

    .benefit {
      display: flex;
      width: 100%;
      max-width: 320px;

      &-text {
        margin-top: -2px;
      }
    }
  }

  .places-of-application-card {
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: max-content;
    width: 100%;
    max-width: 340px;
    flex: 1;
    box-shadow: $c1-default;
    border-radius: 20px;
  }

  @include mq($mobile) {
    padding-top: 56px;

    .header-operation {
      text-align: center;
    }

    .header,
    .subheader {
      text-align: center;
    }

    .benefits-content {
      flex-flow: column nowrap;
      align-items: center;
    }

    .places-of-application-card {
      margin-top: 60px;

      .card-title {
        font-size: 18px !important;
      }
    }
  }

  @include mq(654px) {
    .benefits-content {
      flex-flow: column nowrap;
      align-items: center;

      .benefits-list {
        .benefit {
          max-width: 100%;
        }
      }
    }

    .places-of-application-card {
      margin-top: 60px;
      width: 260px !important;
      padding: 20px;
    }
  }
}
