// @use "./src/scss/utils.scss" as *;
@use "./src/scss/global.scss" as *;

.skey-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: max-content;
  @extend %transition-shadow;

  &.size-normal {
    font-size: 18px;
    font-weight: $regular;
    line-height: 1.5;
    letter-spacing: 0.01em;
  }

  &.primary {
    padding: $size-sm $size-lg;
    border-radius: $size-xs;
    background: $gradident-additional;
    color: $white;

    &:hover {
      box-shadow: $button-hover-shadow;
    }
  }

  &.secondary {
    padding: $size-sm $size-lg;
    border-radius: $size-xs;
    background: $white;
    border: 1px solid $dirty-white-2;
    color: $primary;
    box-shadow: $c1-default;

    &:hover {
      box-shadow: $c1-hover;
      color: $dark;
    }
  }

  &.trietary {
    padding: 10px 0px;
    background: transparent;
    color: $primary;

    &:hover {
      color: $dark;
    }
  }

  &.flat {
    box-shadow: unset;
    &:hover {
      box-shadow: $c1-hover;
    }
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: unset;
  }

  &.size-small {
    font-size: 15px;
  }

  &.size-extra-small {
    font-size: 12px;
    padding: 4px 0px;
  }

  &.dark-mode {
    color: $primary-dark-mode;

    &:hover {
      color: $primary-dark-mode-hover;
    }
  }

  img {
    margin-left: $size-xs;
    @extend %transition-shadow;

    .show {
      opacity: 1;
    }

    .hide {
      opacity: 0;
    }
  }
}
