@use "./global.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

@font-face {
  font-family: Mazzard;
  src: url("../../public/fonts/MazzardH-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Mazzard;
  src: url("../../public/fonts/MazzardH-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;

  font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

body {
  background-color: $main-bg-color;
}

section {
  position: relative;
  padding: 0px 17px;
  overflow-x: hidden;
}

.o-x-hidden {
  overflow-x: clip;
}

hr {
  margin: 0px;
  padding: 0px;
}

a {
  color: unset;
  text-decoration: none;
}

a {
  &.disabled {
    pointer-events: none;
  }
}

button {
  border: none;
  background-color: transparent;
}

.container-xxl {
  max-width: 1440px !important;
  background-color: transparent;
  height: 100%;
  position: relative;
  padding: 0px;
}

.container-xl {
  max-width: 1100px !important;
  background-color: transparent;
  height: 100%;
  position: relative;
  padding: 0px;
}
.row {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
}

.desktop-xs {
  display: block;

  @include mq(360px) {
    display: none;
  }
}
.mobile-xs {
  display: none;

  @include mq(360px) {
    display: block;
  }
}
.desktop-sm {
  display: block;

  @include mq(576px) {
    display: none;
  }
}
.mobile-sm {
  display: none;

  @include mq(576px) {
    display: block;
  }
}
.desktop-md {
  display: block;

  @include mq(768px) {
    display: none;
  }
}
.mobile-md {
  display: none;

  @include mq(768px) {
    display: block;
  }
}
.desktop-lg {
  display: block;

  @include mq(991px) {
    display: none;
  }
}
.mobile-lg {
  display: none;

  @include mq(991px) {
    display: block;
  }
}
.desktop-xl {
  display: block;

  @include mq(1200px) {
    display: none;
  }
}
.mobile-xl {
  display: none;

  @include mq(1200px) {
    display: block;
  }
}
.desktop-xxl {
  display: block;

  @include mq(1400px) {
    display: none;
  }
}
.mobile-xxl {
  display: none;

  @include mq(1400px) {
    display: block;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.as-row {
  @include flex-row(flex-start);
}

.as-col {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.skey-card {
  box-shadow: $c1-default;
  &:hover {
    box-shadow: $c1-hover;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
