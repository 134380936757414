@use "./src/scss/global.scss" as *;

#products-skey-box-integration {
  padding-top: 120px;

  .header {
    @include h1;
    text-align: center;
  }

  .products-integration {
    width: 100%;
    max-width: 530px;
    text-align: center;
  }

  .box-integration {
    width: 74px;
    height: 74px;
  }

  .integration {
    max-width: 340px;
  }

  .simple-integration {
    column-gap: 40px;
    row-gap: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .flex-center {
    @include flex-column(center, flex-start);
  }

  @include mq($mobile) {
    padding-top: 80px;
    .simple-integration {
      row-gap: 40px;
    }
    .header-card {
      font-size: 20px !important;
    }
    .products-integration {
      font-size: 15px !important;
    }
    .integration {
      font-size: 15px !important;
    }
  }
}
