@use "./src/scss/global.scss" as *;

@keyframes appear1 {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.form {
  max-height: 82vh;
  overflow-y: auto;

  //hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  flex-wrap: wrap;
  background-color: $white;
  box-shadow: 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.06), 0px 11.7021px 23.4043px rgba(0, 0, 0, 0.04),
    0px 0px 2.92553px rgba(0, 0, 0, 0.04);
  // max-height: max-content;
  // overflow-y: hidden;
  max-width: 100%;
  width: 529px;
  padding: 40px;
  border-radius: 20px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation: appear1 0.5s forwards cubic-bezier(0, 0.11, 0.29, 1);
  box-sizing: content-box;
  transition: height 0.3s cubic-bezier(0, 0.11, 0.29, 1);

  .overlay {
    height: max-content;
    width: 100%;
  }

  @include mq(576px) {
    width: 85%;
    max-height: 90%;
    top: 10px;
    transform: translate(-50%, 0%);
    padding: 20px;
    overflow-y: scroll;

    .cta-btns {
      flex-direction: column;
      column-gap: 0px;
      row-gap: 10px;

      button {
        width: 100%;
      }
    }
  }
}
