@use "./src/scss/global.scss" as *;

.skey-tix-integration {
  padding-top: 145px;

  &-box {
    display: flex;
    flex-flow: row nowrap;
    gap: 40px 135px;

    .integration-content {
      max-width: 530px;
    }
  }

  @include mq($mobile) {
    padding-top: 100px;

    &-box {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}
