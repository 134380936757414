@use "./src/scss/global.scss" as *;

.accordion-summary {
  width: 100%;
  cursor: pointer;
  padding-bottom: 19px;

  &-row {
    width: 100%;
  }
}
