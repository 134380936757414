@use "../../../../../scss/global.scss" as *;

.rwa-potential {
  padding-top: 110px;

  .list-item {
    &:first-child {
      .description {
        font-family: "Mazzard" !important;
        background: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 28px !important;
      }
    }
  }

  @include mq-max($md) {
    padding-top: 80px;
  }
}
