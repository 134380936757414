@use "./src/scss/global.scss" as *;

.technical-documentation {
  padding-top: 120px;
  padding-bottom: 95px;

  .header {
    @include h1;
    text-align: center;
  }

  .tech-buttons {
    display: flex;
    justify-content: center;
    column-gap: 16px;
  }

  @include mq($mobile) {
    padding-top: 120px;
    padding-bottom: 120px;

    .tech-buttons {
      flex-direction: column;
      column-gap: 0px;
      row-gap: $size-md;

      > * {
        width: 100%;
      }
    }
  }
}
