@use "./src/scss/global.scss" as *;

.skey-tix-poap {
  padding-top: 130px;

  &-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;

    .poap-card {
      padding: 20px;
      width: 340px;
      min-height: 259px;
      background: $white;
      box-shadow: $c1-default;
      border-radius: 20px;
    }
  }

  @include mq($mobile) {
    &-header {
      text-align: center;
    }

    &-cards {
      justify-content: center;
    }
  }

  @include mq(768px) {
    &-cards {
      .poap-card {
        width: 286px;

        .card-image {
          width: 246px;
          height: auto;
          object-fit: scale-down;
        }
      }
    }
  }
}
