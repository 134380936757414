@use "./src/scss/global.scss" as *;

.product-pricing-mobile {
  display: none;
}

@include mq($mobile) {
  .product-pricing-mobile {
    display: block;
    padding: 40px 24px;
    background: #ffffff;
    box-shadow: $c1-default;
    border-radius: 20px;

    .pricing-header {
      opacity: 0.4;
      display: flex;
      width: 100%;
      //width: 92%;
      flex: 1;

      &-serviceName {
        max-width: 200px;
        min-width: 200px;
      }
    }

    .subHeader-starter {
      font-weight: bold !important;
    }

    .offer-service {
      display: flex;
      padding-top: 9px;
    }

    .price {
      color: $primary;
    }

    .hr {
      height: 1px;
    }

    .modules-title {
      background-color: #f7f7f7;
      padding: 7px 0 7px 10px;
      border-radius: 5px;
    }

    .button-offer {
      width: 100%;
      padding: 16px 85px !important;
    }

    .price-offer {
      display: flex;
      justify-content: flex-end;
    }
  }
  //@include mq(500px) {
  //  .product-pricing-mobile {
  //    width: 92%;
  //  }
}
