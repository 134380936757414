@use "./src/scss/global.scss" as *;

#blockchain-access-control {
  padding-top: 120px;

  .header-blockchain {
    @include h1;
    width: max-content;
    width: 100%;
    text-align: center;
  }

  .access {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      max-width: 270px;
    }
  }

  .row-access-control {
    row-gap: 62px;
  }

  .access-header {
    font-size: 28px;
    width: 100%;
    height: max-content;
    flex-direction: row;
    text-align: center;
  }

  .subheader-access {
    text-align: center;
    height: max-content;
    margin: auto;
  }

  @include mq($mobile) {
    .subheader-access {
      max-width: 290px;
      width: 100%;
    }

    .row-access-control {
      row-gap: 55px;
    }
  }
}
