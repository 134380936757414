@use "./src/scss/global.scss" as *;

#development-products {
  padding: 80px 0 189px;

  .card-col {
    margin-bottom: 20px;
  }

  @include mq(768px) {
    padding: 80px 0 253px;
    .card-col {
      margin-bottom: 16px;
    }
  }
}
