@use "../../../../scss/global.scss" as *;

.button-v2 {
  @extend %transition-shadow;

  &.sm {
    height: 40px;
  }

  &.md {
    height: 60px;
  }

  &.lg {
  }

  &.primary {
    padding: $size-sm $size-lg;
    border-radius: $size-xs;
    background: $gradident-additional;
    color: $white;

    &:hover {
      box-shadow: $button-hover-shadow;
    }
  }
  &.secondary {
    padding: $size-sm $size-lg;
    border-radius: $size-xs;
    background: $white;
    border: 1px solid $dirty-white-2;
    color: $primary;
    box-shadow: $c1-default;

    &:hover {
      box-shadow: $c1-hover;
      color: $dark;
    }
  }
  &.tertiary {
    padding: 10px 0px;
    background: transparent;
    color: $primary;

    &:hover {
      color: $dark;
    }
  }

  &.flat {
    box-shadow: unset;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
    box-shadow: none;
    cursor: default;
  }
}
