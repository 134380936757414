@use "./src/scss/global.scss" as *;

#products-blockchain-hero {
  position: relative;
  overflow: hidden;
  padding-top: 140px;

  .subheader {
    width: 100%;
    max-width: 530px;
  }

  .overview-bg-blocks {
    position: absolute;
    top: -148px;
    right: -103px;
    z-index: -1;
  }

  @include mq($desktop) {
    padding-top: 0px;
    .content-col {
      padding-top: 219px;
    }

    .subheader {
      margin: auto;
      text-align: center;
    }

    .overview-bg-blocks {
      left: 50%;
      transform: translateX(-50%);
      top: -61px;
    }

    .buttonHero {
      justify-content: center;
      width: 100% !important;
    }

    .header {
      justify-content: center;
      align-items: flex-end;
      text-align: center;
    }
  }

  @include mq(1195px) {
    .row-hero {
      justify-content: center;
    }
  }
}
