@use "./src/scss/global.scss" as *;

%rotationConst {
  transition: transform 0.6s cubic-bezier(0.69, -0.23, 0.31, 1.23);
}

.interactive-plus {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: unset;

  img {
    height: auto !important;
    width: auto !important;
    position: absolute;
    color: blue;
    left: 0px;
    top: 0px;
    @extend %rotationConst;

    &.vertical-line {
      &.default {
        transform: rotate(0deg);
      }
      &.active {
        transform: rotate(-90deg);
      }
    }

    &.horizontal-line {
      &.default {
        transform: rotate(0deg);
      }
      &.active {
        transform: rotate(-180deg);
      }
    }
  }
}
