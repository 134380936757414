@use "./src/scss/global.scss" as *;

%rotationConst {
  transition: transform 0.6s cubic-bezier(0.69, -0.23, 0.31, 1.23);
}

.accordion-plus-icon {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: unset;

  .plus-line {
    height: auto;
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @extend %rotationConst;
  }

  &-hover {
    .plus-line path {
      stroke: $primary;
    }
  }

  &-active {
    .plus-line path {
      stroke: $primary;
    }

    .vartical {
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    .horizontal {
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }
}
