.flex {
  display: flex;
  // width: max-content;
  // height: max-content;
  max-width: 100%;
  max-height: 100%;

  &.full-size {
    width: 100%;
    height: 100%;
  }

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 100%;
  }
}
