@use "src/scss/global.scss" as *;

.box {
  background-color: $white-0;
  border-radius: 20px;
  padding: 40px;
  box-shadow: $c1-default;
  max-width: 530px;
  width: 100%;

  @include mq-max($md) {
    padding: 24px 16px;
  }
}
