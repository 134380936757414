//colors ============================
$primary: #5c44ec;
$primary-dark-mode: #7f70db;
$primary-dark-mode-hover: #998de2;

$success: #3aa12f;
$danger: #e63a1a;
$warning: #ffaf39;

$white: #ffffff;
$dirty-white: #fafafc;
$dirty-white-1: #f5f5f8;
$dirty-white-2: #eeeef1;
$dirty-white-3: #f3f3f4;
$black: #000000;
$dark: #120e2f;

//COLORS
$white-0: #ffffff;
$shade-1: #f2f2f2;
$shade-2: #e6e6e6;
$shade-3: #d9d9d9;
$shade-4: #cccccc;
$shade-5: #b3b3b3;
$shade-6: #999999;
$shade-7: #808080;
$shade-8: #666666;
$shade-9: #4d4d4d;
$shade-10: #333333;
$shade-11: #262626;
$shade-12: #1a1a1a;
$shade-13: #0d0d0d;
$black-0: #000000;
//COLORS

$main-bg-color: $dirty-white;
//colors =============================

//shadows ============================
$button-hover-shadow: 9.77335px 19.7099px 19px rgba(0, 0, 0, 0.07), 4.94776px 9.97816px 8.28281px rgba(0, 0, 0, 0.04725),
  1.95467px 3.94199px 3.0875px rgba(0, 0, 0, 0.035), 0.427584px 0.86231px 1.09844px rgba(0, 0, 0, 0.02275);

$c1-default: 0px 8px 15px rgba(0, 0, 0, 0.04), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);

$c1-hover: 8px 8px 40px rgba(18, 14, 47, 0.02), 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.04),
  -4px 11.7021px 23.4043px rgba(0, 0, 0, 0.02), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);

$c2: 0px 46.809px 70.213px 0px rgba(0, 0, 0, 0.06), 0px 11.702px 23.404px 0px rgba(0, 0, 0, 0.04),
  0px 0px 2.926px 0px rgba(0, 0, 0, 0.04);

$shadow1: 0px 12px 17px rgba(0, 0, 0, 0.02), 0px 4.62222px 5.41481px rgba(0, 0, 0, 0.0121481),
  0px 0.977778px 1.38519px rgba(0, 0, 0, 0.00785185);

$shadow2: -6px 6px 12px rgba(225, 225, 227, 0.2), 6px -6px 12px rgba(225, 225, 227, 0.2),
  -6px -6px 12px rgba(255, 255, 255, 0.9), 6px 6px 15px rgba(225, 225, 227, 0.9),
  inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(225, 225, 227, 0.5);

$shadow3: 0px 0px 2.92553px rgba(0, 0, 0, 0.04);

$shadow4: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
//shadows ============================

//gradients
$gradient-main: linear-gradient(270deg, #3495ff 0.02%, rgba(92, 68, 236, 0.4) 43.38%, #bb5dee 97.16%);
$gradident-additional: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);

$navbar-height-desktop: 67px;
$navbar-height-mobile: 52px;

//spacing
$size-xs: 8px;
$size-sm: 16px;
$size-md: 24px;
$size-lg: 32px;
$size-xl: 40px;
$size-xxl: 48px;
$size-xxxl: 75px;

//SIZES
$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;
$size-11: $size-dx * 11;
$size-12: $size-dx * 12;
$size-13: $size-dx * 13;
$size-14: $size-dx * 14;
$size-15: $size-dx * 15;
$size-16: $size-dx * 16;
$size-17: $size-dx * 17;
$size-18: $size-dx * 18;
$size-19: $size-dx * 19;
$size-20: $size-dx * 20;
//SIZES

// BREAKPOINTS
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);
//BREAKPOINTS

//ROUNDNESS
$roundness-1: 2px;
$roundness-2: 4px;
$roundness-3: 6px;
$roundness-4: 8px;
$roundness-5: 10px;
$roundness-6: 12px;
$roundness-7: 16px;
$roundness-8: 20px;
$roundness-9: 24px;
$roundness-10: 32px;
$roundness-100: 100vw;
//ROUNDNESS

//brakpoints =========================
$mobile: 991px;
$desktop: 1200px;
//brakpoints =========================

// FONT WEIGHTS
$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black-bold: 900;
// FONT WEIGHTS
