@use "./src/scss/global.scss" as *;

.skey-dropdown {
  position: absolute;
  width: 100%;
  height: max-content;
  max-height: 100vh;
  overflow-y: auto;
  top: 55px;
  left: 0px;
  z-index: 1;
  padding: 24px 0px;
  @include flex-column(center, flex-start);
  background-color: $white;
  box-shadow: $c1-hover;
  border-radius: 0px 0px 10px 10px;
  
  
  button {
    height: 35px;
    width: 100%;
  }

  button + button {
    margin-top: $size-sm;
  }

  > *:hover {
    color: $primary;
  }
}