@use "./global.scss" as *;

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.h1-info {
  @include text-scaffold(48px, 300, $dark, 1.4, -0.01em);
  @include mq($mobile) {
    font-size: 38px !important;
    letter-spacing: -0.02em !important;
  }
}

.h0 {
  @include text-scaffold(60px, 400, $dark, 1.05, -0.01em);
  @include mq($mobile) {
    font-size: 38px;
    letter-spacing: -0.02em;
  }
}

h1,
.h1 {
  @include text-scaffold(48px, 400, $dark, 1.4, -0.01em);
  @include mq($mobile) {
    font-size: 28px !important;
    letter-spacing: -0.02em !important;
  }
}

h2,
.h2 {
  @include text-scaffold(38px, 400, $dark, 1.4, -0.01em);
  @include mq($mobile) {
    font-size: 28px !important;
  }
}

h3 {
  @include text-scaffold(28px, 300, $dark, 1.4, -0.02em);
}

.h3 {
  @include text-scaffold(24px, 300, $dark, 1.4, 0em);
}

h4,
.h4 {
  @include text-scaffold(20px, 300, $dark, 1.4, -0.02em);
}

p {
  @include text-scaffold(12px, 400, $dark, 1.7, 0.02em);
}

.s1 {
  @include text-scaffold(20px, 400, $dark, 1.5, 0.01em);
  @include mq($mobile) {
    font-size: 15px !important;
  }
}

.s2 {
  @include text-scaffold(15px, 400, $dark, 1.7, 0.01em);
  @include mq($mobile) {
    font-size: 12px !important;
  }
}

p.s2-other {
  @include text-scaffold(15px, 400, $dark, 1.7, 0.01em);
  @include mq($mobile) {
    font-size: 14px !important;
  }
}

.p1 {
  @include text-scaffold(12px, $regular, $dark, 1.7, 0.02em);
}

.b1 {
  @include text-scaffold(18px, $regular, $white-0, 1.5, 0.01em);
}

a {
  text-decoration: none;
}

.opacity10 {
  opacity: 0.1;
}

.opacity20 {
  opacity: 0.2;
}

.opacity30 {
  opacity: 0.3;
}

.opacity40 {
  opacity: 0.4;
}

.opacity50 {
  opacity: 0.5;
}

.opacity60 {
  opacity: 0.6;
}

.opacity70 {
  opacity: 0.7;
}

.opacity80 {
  opacity: 0.8;
}

.opacity90 {
  opacity: 0.9;
}

.opacity100 {
  opacity: 1;
}

.gray10 {
  color: #fafafc;
}

.gray20 {
  color: #f7f7f8;
}

.gray30 {
  color: #f3f3f4;
}

.gray40 {
  color: #e8e7eb;
}

.gray50 {
  color: #d0cfd5;
}

.gray60 {
  color: #b8b7c1;
}

.gray70 {
  color: #a09fac;
}

.gray80 {
  color: #898797;
}

.gray90 {
  color: #716e82;
}

.bg-gray10 {
  background-color: #fafafc;
}

.bg-gray20 {
  background-color: #f7f7f8;
}

.bg-gray30 {
  background-color: #f3f3f4;
}

.bg-gray40 {
  background-color: #e8e7eb;
}

.bg-gray50 {
  background-color: #d0cfd5;
}

.bg-gray60 {
  background-color: #b8b7c1;
}

.bg-gray70 {
  background-color: #a09fac;
}

.bg-gray80 {
  background-color: #898797;
}

.bg-gray90 {
  background-color: #716e82;
}

.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}

.space-xs {
  height: $size-xs;
}

.space-sm {
  height: $size-sm;
}

.space-md {
  height: $size-md;
}

.space-lg {
  height: $size-lg;
}

.space-xl {
  height: $size-xl;
}

.space-xxl {
  height: $size-xxl;
}

.space-xxxl {
  height: $size-xxxl;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.w-max {
  width: max-content;
}

.mb-xs {
  margin-bottom: 8px;
}

.mb-sm {
  margin-bottom: 16px;
}

.mb-md {
  margin-bottom: 24px;
}

.mb-lg {
  margin-bottom: 32px;
}

.mb-xl {
  margin-bottom: 40px;
}

.mb-xxl {
  margin-bottom: 48px;
}

.ml-xs {
  margin-left: 8px;
}

.mr-xs {
  margin-right: 8px;
}

.mt-xs {
  margin-top: 8px;
}

.ml-sm {
  margin-left: 16px;
}

.mr-sm {
  margin-right: 16px;
}

.mt-sm {
  margin-top: 16px;
}

.ml-md {
  margin-left: 24px;
}

.mr-md {
  margin-right: 24px;
}

.mt-md {
  margin-top: 24px;
}

.ml-lg {
  margin-left: 32px;
}

.mr-lg {
  margin-right: 32px;
}

.mt-lg {
  margin-top: 32px;
}

.ml-xl {
  margin-left: 40px;
}

.mr-xl {
  margin-right: 40px;
}

.mt-xl {
  margin-top: 40px;
}

.ml-xxl {
  margin-left: 48px;
}

.mr-xxl {
  margin-right: 48px;
}

.mt-xxl {
  margin-top: 48px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
