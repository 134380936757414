@use "./src/scss/global.scss" as *;

#faq-hero {

  .hero-shape {
    position: absolute;
    right: -64px;
    top: -66px;
    z-index: -1;
    clip-path: inset(1px 1px);
  }

  .faq-content-col {
    padding-top: 93px;
    padding-bottom: 141px;
  }

  @include mq(991px) {
    .hero-shape {
      right: unset;
      left: -35px;
      top: -51px;
    }

    .faq-content-col {
      padding-top: 112px;
      padding-bottom: 116px;
    }
  }
}