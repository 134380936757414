@use "../../../scss/global.scss" as *;

.group {
  display: flex;
  flex-direction: row;

  &.full-size {
    height: 100%;
    width: 100%;
  }

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 100%;
  }
}
