@use "../../../scss/global.scss" as *;

.explainer {
  width: 700px;
  height: 395px;
  border-radius: 10px;
  overflow: hidden;
  @include flex-center;

  .video {
    border-radius: 10px;
  }

  @include mq-max($md) {
    width: 100%;
    height: auto;
    // height: 230px;
  }
}
