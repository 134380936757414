@use "./src/scss/global.scss" as *;

.modules-form {
  max-width: 640px;
  width: 100%;

  .row {
    --bs-gutter-x: 40px;
  }

  @include mq(760px) {
    width: 85%;
    max-height: 90%;
    top: 10px;
    transform: translate(-50%, 0%);
    padding: 20px;
    overflow-y: scroll;

    .cta-btns {
      flex-direction: column;
      column-gap: 0px;
      row-gap: 10px;

      button {
        width: 100%;
      }
    }
  }
}

.cta-btns {
  display: flex;

  column-gap: $size-sm;

  .same-width {
    width: 185px;
  }
}
