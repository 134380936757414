@use "./src/scss/global.scss" as *;

.products-skey-tix-features {
  padding-top: 135px;

  .header-skey-tix {
    @include h1;
    text-align: center;
  }

  .skey-tix {
    width: max-content;
    width: 100%;
    text-align: center;
    font-size: 28px;
  }

  .row-skey-tix {
    row-gap: 60px;
  }

  .card-link {
    font-size: 15px;
  }

  .subheader-tix {
    text-align: center;
    height: max-content;
    margin: auto;
  }

  @include mq($mobile) {
    padding-top: 80px;

    .subheader-tix {
      max-width: 290px;
      width: 100%;
    }

    .row-skey-tix {
      row-gap: 50px;
    }
  }
}
