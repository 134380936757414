@use "../../../../../scss/global.scss" as *;

.more-info {
  padding-top: 200px;
  padding-bottom: 224px;

  .generic-link {
    height: 62px;
    padding: 0px 16px;
    box-shadow: $c1-default;
    p {
      font-size: 16px;
      color: $primary !important;
    }
    border-radius: 8px;
    border: 1px solid $dirty-white-2;

    @extend %transition-shadow;

    &:hover {
      box-shadow: $c1-hover;
    }
  }

  @include mq-max($lg) {
    padding-top: 120px;
    padding-bottom: 150px;

    .generic-link {
      width: 100%;
    }
  }
}
