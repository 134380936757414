@use "./src/scss/global.scss" as *;

#skey-box-products {
  padding-top: 60px;

  .grid-cards-one {
    gap: 40px;
  }

  .grid-cards-box {
    gap: 40px;
    padding-top: 68px;
  }

  //
  //.grid-cards-gap-20 > * {
  //  width: 100%;
  //  margin-bottom: 5px;
  //}

  .grid-cards-gap-20 {
    column-gap: 40px;
  }

  div {
    font-size: 15px;
  }

  .skey-features {
    //width: 528px;
    min-height: 313px;
  }

  .imageBoxProducts {
    width: max-content;
  }

  ul {
    padding-left: 1rem;
  }

  @include mq($mobile) {
    padding-top: 0px;
    .imageBoxProducts {
      margin-top: 60px;
    }
    .grid-cards-box {
      padding-top: 0px;
    }
    .products-skey-box {
      max-width: 340px;
      max-height: 354px;
    }
    .skey-box-header {
      max-width: 340px;
      max-height: 79px;
    }
    .subheader {
      max-width: 340px;
      max-height: 290px;
    }
  }
}
