@use "./src/scss/global.scss" as *;

.accordion-tab-content {
  z-index: 2;
  transition: height 0.5s cubic-bezier(.69, -0.23, .31, 1.23);
  overflow-y: hidden;
  width: 100%;
  will-change: height;

  .skey-link {
    padding-left: 10px; 
    width: max-content;
  }

  .content-row {
    width: 100%;
    --bs-gutter-x: 20px;
  }
}