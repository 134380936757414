@use "./src/scss/global.scss" as *;

#overview-skey-ecosystem {
  .skey-ecosystem-bg {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }

  .container-xxl {
    transform: translateY(-290px);
  }

  .main-col {
    margin-left: calc(2.6 / 12 * 100%);
    @include flex-column(center, flex-start);

    * {
      text-align: center;
    }
  }

  .header {
    @include h1;
    width: 100%;
    max-width: 450px;
  }

  .subheader {
    width: 100%;
    max-width: 530px;
  }

  .links {
    @include flex-row(flex-start);

    a + a {
      margin-left: 20px;
    }
  }

  @include mq($mobile) {
    margin-bottom: -25px;
    .main-col {
      margin-left: unset;
      transform: translateY(71px);
    }

    .header {
      font-size: 38px !important;
    }

    .subheader {
      max-width: 400px;
    }

    .container-xxl {
      transform: translateY(-200px);
    }

    .links {
      @include flex-column(flex-start, flex-start);
      width: 100%;

      a {
        width: 100% !important;
      }

      a + a {
        margin-left: unset;
        margin-top: 20px;
      }
    }
  }
}
