@use "./src/scss/global.scss" as *;

.grid-cards {
  -moz-column-fill: balance;
  column-fill: balance;

  > * {
    display: inline-block;
    width: 100%;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.grid-cards-col-3 {
  -moz-column-count: 3;
  column-count: 3;

  @include mq(991px) {
    -moz-column-count: 2;
    column-count: 2;
  }

  @include mq(768px) {
    -moz-column-count: 1;
    column-count: 1;
  }
}

.grid-cards-col-2 {
  -moz-column-count: 2;
  column-count: 2;

  @include mq(768px) {
    -moz-column-count: 1;
    column-count: 1;
  }
}

.grid-cards-gap-10 {
  -moz-column-gap: 10px;
  column-gap: 10px;

  > * {
    margin-bottom: 10px;
  }
}

.grid-cards-gap-15 {
  -moz-column-gap: 15px;
  column-gap: 15px;

  > * {
    margin-bottom: 15px;
  }
}

.grid-cards-gap-20 {
  -moz-column-gap: 20px;
  column-gap: 20px;

  > * {
    margin-bottom: 20px;
  }
}
