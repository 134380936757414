@use "./src/scss/global.scss" as *;

@keyframes appear2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.success-form-content {

  &.appear {
    animation: appear2 0.5s cubic-bezier(.69, -0.23, .31, 1.23);
  }
}