@use "./src/scss/global.scss" as *;

#nav-bar {
  display: flex;
  flex-direction: row;
  background-color: white;
  box-shadow: $shadow1;
  height: $navbar-height-desktop;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 6;
  overflow: unset;

  @include mq($mobile) {
    height: $navbar-height-mobile;
  }
}

.navbar-background {
  height: $navbar-height-desktop;
  width: 100%;
}

@include mq($mobile) {
  #navbar,
  .navbar-background {
    height: $navbar-height-mobile;
  }
}
