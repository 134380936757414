@use "../../../../../scss/global.scss" as *;

.main-benefits {
  padding-top: 150px;
  .benefit {
    width: 340px;
  }
  @include mq-max($sm) {
    padding-top: 100px;
    .benefit {
      // width: 240px;
    }
  }
}
