@use "./src/scss/global.scss" as *;

.articleCard {
  //padding: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: max-content;

  .date {
    font-size: 12px;
    opacity: 0.6;
    color: $dark;
  }
  p.s2 {
    color: $primary;
  }

  .link {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .title {
    width: 100%;
    max-width: 400px;
    height: max-content;
    font-weight: $regular;
    font-size: 20px !important;
    @include textEllipsisAfterLines(2);
  }

  @include mq($mobile) {
    min-height: unset;
  }
}
