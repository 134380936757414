@use "./src/scss/global.scss" as *;

.tab-interactive-date {
  @include flex-column(center, flex-start);
  width: 100%;

  .interactive-text {
    width: max-content;
    > * {
      font-family: Mazzard !important;
      @include text-scaffold(48px, 600, unset, 57px, -0.06em);
      width: max-content;
      min-width: 110px;
      text-align: center;
      &.active {
        background: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      &.default {
        background: linear-gradient(270.16deg, $dark 0.02%, $dark 97.16%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  @include mq($desktop) {
    width: max-content;
    align-items: flex-start;

    h1 {
      font-size: 48px !important;
    }

    .interactive-text {
      > * {
        &.default {
          background: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
}
