@use "./src/scss/global.scss" as *;

#products-skey-access-control-hero {
  padding-top: 120px;

  .header {
    width: 100%;
    max-width: 530px;
  }
  
  .access-control-hero {
    width: max-content;
    position: absolute;
    left: 612px;
    top: -119px;
  }

  .subheader {
    width: 100%;
    max-width: 530px;
  }

  @include mq($desktop) {
    padding-top: 0px;

    .content-col {
      padding-top: 258px;
    }

    .header, .subheader {
      margin: auto;
    }
    
    .header {
      justify-content: center;
      align-items: flex-end;
      text-align: center;
    }

    .subheader {
      text-align: center;
    }

    .buttonHero {
      justify-content: center;
      width: 100% !important;
    }

    .access-control-hero {
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
    }
    .row-hero {
      justify-content: center;
    }
  }
}
