@use "./src/scss/global.scss" as *;

#products-skey-box-hero {
  padding-top: 120px;

  .skey-box-hero {
    position: absolute;
    width: max-content;
    height: max-content;
    left: 776px;
    top: -190px;
  }

  .subheader {
    font-size: 20px;
  }

  .header {
    @include h1;
    width: 100%;
    max-width: 340px;
  }

  .buttons {
    @include flex-row(flex-start);
    gap: 20px;
    width: 100%;
  }

  @include mq($desktop) {
    .skey-box-hero {
      left: 50%;
      top: -151px;
      transform: translateX(-50%);
    }
    .row-box-hero {
      width: 100%;
      justify-content: center;
      padding-top: 270px;
    }
    .content-col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .subheader {
      text-align: center;
      font-size: 15px !important;
    }

    .buttons {
      justify-content: center;
    }

    .header {
      text-align: center;
    }
  }

  @include mq($mobile) {
    .skey-box-hero {
      left: 47%;
      top: -151px;
      transform: translateX(-50%);
    }

    .row-box-hero {
      width: 100%;
      justify-content: center;
      padding-top: 270px;
    }
    .content-col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .subheader {
      font-size: 15px !important;
    }
    .buttons {
      @include flex-column(center, flex-start);
      width: 100%;

      a {
        width: 100%;
      }

      .skey-button {
        width: 100%;
      }
    }
    .header {
      text-align: center;
    }
  }
}
