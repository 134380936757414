@use "./src/scss/global.scss" as *;

.as-link {
  height: auto;
  width: max-content !important;
}

.image {
  max-height: 100%;
  height: auto;
  width: max-content;
}

