@use "./src/scss/global.scss" as *;

#products-skey-box-features {
  padding-top: 160px;

  .header-skey-box {
    @include h1;
    justify-content: center;
    text-align: center;
  }

  .products-box {
    max-width: 527px;
    max-height: 136px;
    text-align: center;
  }

  .flex-center {
    @include flex-column(center, flex-start);
  }

  @include mq($mobile) {
    padding-top: 80px;
    .products-box {
      font-size: 15px !important;
    }
  }
}
