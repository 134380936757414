@use "./src/scss/global.scss" as *;

.skey-tix-nft-module {
  padding-top: 100px;

  &-main {
    display: flex;
    flex-flow: row nowrap;
    column-gap: 130px;
  }

  @include mq($mobile) {
    &-main {
      flex-flow: column;
      align-items: center;
    }
  }
}
