@use "./src/scss/global.scss" as *;

#skey-network-today {
  position: relative;
  overflow: hidden;
  min-height: 600px;
  padding-top: 141px;

  .header {
    @include h1;
    width: 100%;
    max-width: 420px;
  }

  .subheader {
    width: 100%;
    max-width: 430px;
  }

  .chart {
    position: absolute;
    left: 200px;
    top: 150px;
    max-height: unset;
    z-index: -1;
  }

  .skey-stock-exchange-data {
    @include flex-row(flex-start);
    flex-wrap: wrap;

    .interactive-data-holder {
      width: 45%;
      max-width: 100%;
      margin-right: $size-xl;
      margin-bottom: $size-md;
    }
  }

  &:nth-child(1) {
    margin-left: -2px;
  }

  .gradient-text {
    font-size: 48px !important;
    width: max-content;

    > * {
      @include text-scaffold(60px, 700, unset, 1, -0.015em);
      font-family: "Mazzard" !important;
      background: linear-gradient(270.16deg, #3495ff 0.02%, #5c44ec 97.16%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @include mq($mobile) {
    min-height: unset;
    padding-top: 80px;

    .chart {
      position: relative;
      left: 43%;
      transform: translateX(-50%);
      top: 20px;
    }

    .skey-stock-exchange-data {
      .interactive-data-holder {
        margin-bottom: $size-md;
        width: 100%;
      }

      p {
        line-height: 1.2;
      }
    }

    .interactive-text {
      > * {
        font-size: 48px !important;
      }
    }
  }

  @include mq(375px) {
    .interactive-text {
      > * {
        font-size: 38px !important;
      }
    }
  }
}
