@use "./src/scss/global.scss" as *;

#products-skey-box-platform {
  padding-top: 100px;

  .header-panel-box {
    text-align: center;
    @include h1;
  }

  @include mq($mobile) {
    padding-top: 66px;
  }
}
