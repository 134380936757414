@use "./src/scss/global.scss" as *;

.widget-section {
  position: relative;
  z-index: 5;
  padding: 0px !important;

  .box-scroller-wrapper {
    // width: 10px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    background-color: red;

    .box-scroller {
      $self-height: 62px;
      position: sticky;
      position: -webkit-sticky;
      top: calc(100vh - $self-height);
      // top: 0px;
      left: 0px;
      height: $self-height;
      z-index: -1;

      .scroller-content {
        background: $white;
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: calc(100vw - $size-sm);
        height: 100%;

        z-index: 1;

        box-shadow: $shadow4;

        @include mq($md) {
          width: 100vw;
        }
      }
    }
  }

  .box-widget {
    background: $main-bg-color;

    .widget-wrapper {
      height: 580px;

      @include mq(817px) {
        height: 800px;
      }

      @include mq(567px) {
        height: 880px;
      }
    }
  }

  .interactive-text {
    p {
      color: $primary;
    }
  }

  .header {
    @include h1;
  }

  .copy-btn {
    padding: 8px;
    border-radius: 10px;
    transition: background 0.2s ease-in-out;
    &:hover {
      background: rgba($primary, 0.1);
    }
  }
}
