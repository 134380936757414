@use "./src/scss/variables.scss" as *;

%transition-shadow {
  transition: box-shadow 0.3s ease-out;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-row($direction) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: $direction;
}

@mixin flex-column($v-direction, $h-direction: center) {
  display: flex;
  flex-direction: column;
  align-items: $v-direction;
  justify-content: $h-direction;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@mixin capitalize() {
  & *::first-letter {
    text-transform: uppercase;
  }
}

@mixin absolute-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-center-x() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin relative-center() {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin relative-center-x() {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@mixin relative-center-y() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin text-scaffold($fs, $fw, $color, $lh, $ls) {
  font-size: $fs;
  font-weight: $fw;
  color: $color;
  line-height: $lh;
  letter-spacing: $ls;
}

@mixin v-img($width) {
  height: auto;
  width: $width;
}

@mixin h-img($height) {
  width: auto;
  height: $height;
}

@mixin fit-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@mixin hero-img($url) {
  background-image: url($url);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin submissiveBox {
  width: max-content;
  height: max-content;
}

@mixin fullBox {
  width: 100%;
  height: 100%;
}

@mixin halfSubmissive {
  height: max-content;
  width: 100%;
}

@mixin mq-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin mq($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin desktop($display, $size) {
  display: $display !important;

  @include mq($size) {
    display: none !important;
  }
}

@mixin mobile($display, $size) {
  display: none !important;

  @include mq($size) {
    display: $display !important;
  }
}

@mixin border($border-color) {
  border: 1px solid $border-color;
}

@mixin textEllipsisAfterLines($linesCount) {
  display: -webkit-box;
  -webkit-line-clamp: $linesCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin h1 {
  @include text-scaffold(48px, 400, $dark, 1.4, -0.01em);
  @include mq($mobile) {
    font-size: 28px !important;
    letter-spacing: -0.02em !important;
  }
}

@mixin h2 {
  @include h1;
  font-size: 48px;
}
