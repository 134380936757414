@use "./src/scss/global.scss" as *;

.products-skey-tix-hero {
  padding-top: 120px;

  .skey-tix-hero {
    width: max-content;
    position: absolute;
    right: 0px;
    top: -170px;
  }

  .row-hero-col {
    max-width: 530px;
  }

  .header-skey-tix {
    text-align: center;
  }

  .buttons {
    @include flex-row(flex-start);
    gap: 20px;
    width: 100%;
  }

  @include mq($desktop) {
    padding-top: 0px;

    .skey-tix-hero {
      left: 50%;
      transform: translateX(-50%);
      top: -100px;
    }

    .row-hero {
      justify-content: center;
      text-align: center;
    }

    .row-hero-col {
      padding-top: 290px;
    }

    .buttons {
      justify-content: center;
    }
  }

  @include mq($mobile) {
    .buttons {
      @include flex-column(center, flex-start);
      width: 100%;

      a {
        width: 100%;
      }

      .skey-button {
        width: 100%;
      }
    }
  }
}
