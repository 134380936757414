@use "./src/scss/global.scss" as *;

.skey-tix-why-nft {
  padding-top: 120px;

  &-cards {
    display: flex;
    flex-flow: row nowrap;
    gap: 41px;

    .why-nft-card {
      max-width: 340px;
      text-align: center;

      .title {
        font-size: 28px;
        font-weight: $regular;
      }
    }
  }

  @include mq($mobile) {
    .header-main {
      text-align: center;
    }

    &-cards {
      flex-flow: row wrap;
      justify-content: center;
    }
  }
}
