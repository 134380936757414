@use "./src/scss/global.scss" as *;

#overview-hero {
  padding-top: 180px;

  .content-col {
    z-index: 1;
  }

  .subheader {
    width: 100%;
    max-width: 405px;
  }
  .overview-bg-blocks {
    position: absolute;
    right: 0px;
    top: -240px;
    z-index: 0;
  }

  .PartnershipsOverview {
    padding-top: 130px;
  }

  @include mq($desktop) {
    padding-top: 383px;

    .subheader {
      margin: auto;
      max-width: 260px;
      text-align: center;
    }

    .overview-bg-blocks {
      left: 50%;
      transform: translateX(-50%);
      top: -480px;
    }

    .buttonHero {
      justify-content: center;
      width: 100% !important;
    }

    .ecosystemHero {
      justify-content: center;
      align-items: flex-end;
      text-align: center;
    }

    @include mq(1195px) {
      .row-hero {
        justify-content: center;
      }
    }
  }
}
