@use "./src/scss/global.scss" as *;

.box-platform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  position: relative;
  max-width: 530px;
  min-height: 228px;
  background: #ffffff;
  box-shadow: $c1-default;
  border-radius: 20px;
}

.col-box-panel {
  flex-direction: row;
  position: relative;
}

.image-box {
  display: flex;
  max-width: 244px;
  max-height: 137.76px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.04));
  border-radius: 4.44034px;
  position: relative;
  z-index: 1;
  top: 20%;
  left: 45%;
}

.subheader-box {
  font-size: 15px;
}

@include mq($mobile) {
  .image-box {
    top: 35px;
    left: 0px;
    margin: 0 auto;
  }
  .s1.header {
    font-size: 20px !important;
  }

  .s2.title {
    font-size: 15px !important;
  }
}
