@use "./src/scss/global.scss" as *;

.product-card {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: $white;
  transition: box-shadow 0.3s ease-in-out;

  &.default {
    box-shadow: $c1-default;
  }

  &.hover {
    box-shadow: $c1-hover;
  }

  &.active {
  }

  .as-link {
    > * {
     
      color: $primary;
      font-weight: $regular;
    }
  }

  .subheader {
  }

  .logo-wrapper {
    position: relative;
    width: 35.5px;
    height: 20.2px;
  }

  .product-card-inside {
    padding: 30px 20px;
    width: 100%;

    .card-head {
      @include flex-row(space-between);
    }
  }
}
