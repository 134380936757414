@use "./src/scss/global.scss" as *;

#overview-products {
  // padding-bottom: 170px;

  .main-row {
    display: flex;
    flex-direction: row;
  }

  .content-col {
    @include flex-column(flex-start, center);
  }

  .phone-col {
    @include flex-center;
  }

  .overview-products-graphic {
    // position: absolute;
    right: 0px;
    top: 0px;
    width: auto;
    height: auto;
    max-height: unset;
  }

  .header {
    width: 100%;
    max-width: 444px;
    @include h1;
  }

  .subheader {
    width: 100%;
    max-width: 444px;
  }

  @include mq($mobile) {
    margin-top: -64px;
    margin-bottom: -94px;
    .content-col {
      @include flex-center;
      transform: translateY(-171px);

      * {
        text-align: center;
        width: 100% !important;
      }
    }
  }
}
