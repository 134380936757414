@use "./src/scss/global.scss" as *;

.chips {
  border-radius: $roundness-100;
  height: 40px;
  width: max-content;
  // padding: 4px;
  background: $dirty-white-1;

  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.97);
  }
}
