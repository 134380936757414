@use "./src/scss/global.scss" as *;

#overview-blockchain {
  padding-top: 80px;

  .header {
    @include h1;
    width: 100%;
  }

  .subheader {
    max-width: 100%;
    width: 100%;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    order: 1;
  }

  .skey-link + .skey-link {
    margin-left: $size-sm;
  }

  .col-blockchain {
    display: flex;
    flex-wrap: wrap;
    padding-top: 146px;
    flex-direction: row;
    column-gap: 41px;
    row-gap: 72px;
  }

  .card-network {
    display: flex;
    flex-direction: column;
    width: 45%;
  }

  overview-card-img {
    width: 80px;
    height: auto;
  }

  .img {
    box-shadow: 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.04), 0px 11.7021px 23.4043px rgba(0, 0, 0, 0.04),
      0px 0px 2.92553px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
  }

  @include mq($mobile) {
    padding-top: 80px;

    .header,
    .subheader {
      text-align: center;
      margin: auto;
    }

    .subheader {
      text-align: center;
      max-width: 500px;
    }

    .col-blockchain {
      padding-top: 80px;
      column-gap: unset;
      row-gap: 40px;
    }

    .card-network {
      width: 100%;
      align-items: center;
    }

    .buttons {
      flex-direction: column;
    }

    .skey-link {
      margin-left: unset !important;
      width: 100% !important;
    }

    .skey-link + .skey-link {
      margin-top: $size-sm;
    }

    .s1.subheader:nth-child(1) {
      max-width: 340px;
      font-size: 20px !important;
    }
  }
}
