// @use "./src/scss/utils.scss" as *;
@use "./src/scss/global.scss" as *;

.solution-skey-card {
  border-radius: 20px;
  @extend %transition-shadow;
  color: #5c44ec;
  overflow: hidden;
  box-shadow: $c1-default;

  $base-max-width: 350px;

  width: 100%;
  max-width: $base-max-width;

  // max-height: 536px;
  // height: max-content;

  &.big {
    max-width: 720px;
  }

  &:hover:not(&.disabled) {
    box-shadow: $c1-hover;
  }

  .disabled {
    pointer-events: none !important;
  }

  .title-card {
    font-size: 28px !important;
  }

  .link {
    color: $primary;
    &-disabled {
      * {
        color: $dark !important;
      }
      opacity: 0.4;
    }
  }

  &-container {
    @include flex-column(flex-start, flex-start);
    height: max-content;
    min-height: 536px;
    background-color: $white;
    border-radius: 20px;
    max-width: 100%;
    position: relative;

    &.disabled {
      pointer-events: none !important;
      box-shadow: none;
    }

    .header-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
      object-position: 50% 50%;
    }

    .header-imageOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $gradient-main;
      mix-blend-mode: screen;
      opacity: 0.8;
    }

    .content {
      padding: 40px;
      padding-bottom: 30px;

      @include flex-column(flex-start, flex-start);
      flex: 1;
      justify-content: space-between;
    }

    .interactive-text > * {
      color: $primary;
    }
  }

  @include mq-max($md) {
    &.big {
      max-width: $base-max-width;
    }
  }

  @include mq($mobile) {
    &-container {
      min-height: unset;
    }

    .content {
      padding: 20px;
    }

    p.s2 {
      font-size: 15px !important;
    }
  }
}
