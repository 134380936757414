@use "../../../../../scss/global.scss" as *;

.how-it-works {
  padding-top: 175px;

  .section-img {
    max-width: 100%;
  }

  @include mq-max($md) {
    padding-top: 80px;
  }
}
