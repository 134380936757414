@use "./src/scss/global.scss" as *;

#deflation-system {
  padding-top: 120px;

  .header {
    text-align: center;
  }

  .deflation-img {
    max-width: 100%;
    width: auto;
    margin: auto;
  }

  @include mq(768px) {
    padding-bottom: 80px;
  }
}
