@use "./src/scss/global.scss" as *;

.menu-bar {
  width: max-content !important;
  height: 100%;
  flex-direction: row;
  align-items: center;
}
.hamburger {
  width: max-content !important;
  height: max-content !important;
}

.tooltip-wrapper {
  position: relative;
}

.nav-tooltip {
  height: max-content;
  position: absolute;
  width: 240px;
  right: 50%;
  top: 50%;
  // @include flex-column(center, flex-start);
  background-color: $white;
  box-shadow: 8px 8px 40px rgba(18, 14, 47, 0.02), 0px 46.8086px 70.2128px rgba(0, 0, 0, 0.04),
    -4px 11.7021px 23.4043px rgba(0, 0, 0, 0.02), 0px 0px 2.92553px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 24px;
  max-width: unset;
  max-height: unset;
}

.menu-link {
  @include flex-center;
  height: 100%;
  padding: 12px;
  width: 100%;
}
