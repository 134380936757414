@use "./src/scss/global.scss" as *;

.articleImageCard {
  position: relative;
  display: block;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: $white;
  box-shadow: $c1-default;
  cursor: pointer;
  border-radius: 20px;
  @extend %transition-shadow;

  p.s2 {
    color: $primary;
  }

  &:hover {
    box-shadow: $c1-hover;
  }

  .articleCard {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  &-image {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 250px;
    object-fit: cover;
    margin-bottom: $size-sm;
    border-radius: 10px;
    cursor: pointer;
  }

  @include mq($mobile) {
    min-height: unset;
    &-image {
      max-height: 145px;
    }
  }
}
