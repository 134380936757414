@use "./src/scss/global.scss" as *;

.skey-tix-administrations-cards {
  .cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .administration-card {
    padding: 20px;
    width: 340px;
    min-height: 259px;
    background: $white;
    box-shadow: $c1-default;
    border-radius: 20px;
  }

  @include mq(768px) {
    .administration-card {
      width: 286px;

      .card-image {
        width: 246px;
      }
    }
  }
}
