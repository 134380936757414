@use "./src/scss/global.scss" as *;

#cooperation-hero {
  padding-top: 325px;

  .header {
    text-align: center;
    font-size: 56px;
  }

  .subheader {
    text-align: center;
    width: 100%;
    max-width: 440px;
  }
  .subheader-skey-tix {
    width: 100%;
    max-width: 530px;
  }

  .flex-center {
    @include flex-column(center, flex-start);
  }

  .cooperation-bg-blocks {
    position: absolute;
    top: -188px;
    left: 50%;
    transform: translateX(-50%);
  }


  @include mq($mobile) {
    padding-top: 272px;

    .cooperation-bg-blocks {
      top: -57px;
    }
  }
}
