@use "../../../../../scss/global.scss" as *;

.benefits {
  padding-top: 150px;
  .benefit {
    width: 100%;
    max-width: 340px;
  }
  @include mq-max($sm) {
    padding-top: 80px;
    // .benefit {
    //   width: 240px;
    // }
  }
}
