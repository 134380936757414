@use "./src/scss/global.scss" as *;

.docs-card {
  text-align: center;

  @include flex-column(center, flex-start);

  > * {
    max-width: 280px !important;
  }

  .header {
    font-size: 28px;
    // max-width: 275px !important;
    width: 100%;
    font-weight: $regular;
  }

  .subheader {
    margin: auto;
    // max-width: 340px !important;
  }

  @include mq($mobile) {

    .header {
      margin: auto;
    }

    .subheader {
      max-width: 340px !important;
      width: 100%;
      font-size: 15px !important;
    }
  }
}
